@import "dark";

$body-bg:    $white;
$body-color: $dark;

$link-color: $info;
$nav-pills-link-active-bg: $info;


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap');
$font-family-base: 'Noto Sans Mono', monospace;
