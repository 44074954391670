$white:      #0a0a0a;

$light:      #272822;
$dark:       #d0d0d0;
$primary:    #66aa11;
$secondary:  #5f5fee;
$info:       #4eb4fa;
$success:    #80ff00;
$warning:    #ffba68;
$danger:     #ff0090;


